.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-size: 2rem;
  color: white;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
}

h3 {
  text-align: center;
  font-size: 1.4rem;
  color: white;
  margin-bottom: 1rem;
  font-weight: bold;
}

h4 {
  text-align: left;
  font-size: 1.2rem;
  color: white;
  margin-bottom: 1rem;
  margin-left: 2%;
  margin-right: 2%;
  font-weight: bold;
}

h5 {
  text-align: left;
  font-size: 1.5rem;
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}

p {
  font-size: 1.2rem;
  line-height: 1.5;
  color: white;
  margin-bottom: 1rem;
  text-align: left;
  margin-left: 2%;
  margin-right: 2%;
}

pre {
  font-size: 1.2rem;
  line-height: 1.5;
  color: white;
  margin-bottom: 1rem;
  text-align: left;
} 

textarea {
  width: 100%;
  height: 400px;
  padding: 10px;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
}

input {
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-bottom: 1rem;
  font-size: 1rem;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
}

select {
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-bottom: 1rem;
  color: white;
}

li {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 1rem;
  padding: 10px;
  color: white;
}

figcaption {
  color: white;
  text-align: left;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 1.5rem;
  font-style: italic;
}

blockquote {
  color: white;
  text-align: left;
  margin-left: 10%;
  margin-right: 10%;
  font-style: italic;
  border-left: 2px solid white;
  padding-left: 10px;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

figure {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 1rem;
  padding: 10px;
  color: white;
}

.modal {
  background-color: white;
  color: black;
}

.modal h1, .modal h2, .modal p, .modal li, .modal textarea {
  color: black;
}

.spinner {
  color: white;
}

.spinner-border {
  color: white;
}


